/* material ui dropdown icon alignment to center */
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    padding-bottom: 2px;
}


/* Issue with UI on screens <= 1024px screen has opacity issue - solved */
.css-1scr8os {
    position: relative !important;
}

.cursor-pointer {
    cursor: pointer;
}

/* error validation p tag style */
/* p.MuiFormHelperText-root.Mui-error.MuiFormHelperText-sizeMedium.MuiFormHelperText-contained.css-ex2wus-MuiFormHelperText-root {
    margin: 0;
}

p#mui-14-helper-text {
    margin-left: 0;
} */

[id^="mui-"][id$="-helper-text"] {
    margin-left: 0;
}


.position-relative {
    position: relative !important;
}